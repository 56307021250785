/**
 * In capable browsers all "menu item" elements on the page are enhanced to do
 * an animated scroll (transition) to their target.
 * Uses CSS `translateY` instead of `.scrollTop` property for animation @ 60fps.
 * Default browser behaviour - update hash - is kept intact.
 */

const SELECTOR = '[data-menu-item]';
const body = document.body;
const isCapableBrowser = ('transition' in body.style && 'transform' in body.style);
const TRANSITION = 'transform .3s ease-in-out';
let currentScrollPosition = 0;
let ticking = false;

function init() {
	if (!isCapableBrowser) {
		return;
	}

	const items = [].slice.call(document.querySelectorAll(SELECTOR));
	items.forEach(animatedAnchor);

	window.addEventListener('scroll', onScroll);
}

function onScroll() {
	if (!ticking) {
		window.requestAnimationFrame(function() {
			currentScrollPosition = window.scrollY;
			ticking = false;
		});

		ticking = true;
	}
}

function animatedAnchor(anchor) {
	const targetId = anchor.href.split('#')[1];
	const target = document.getElementById(targetId);

	anchor.addEventListener('click', function(event){
		event.preventDefault();
		scrollTo(target);
	}, false);
}

function scrollTo(target) {
	const bodyTop = body.getBoundingClientRect().top;
	const targetTop = target.getBoundingClientRect().top;
	const offsetTop = targetTop - bodyTop;

	body.addEventListener('transitionend', onTransitionEnd, false);
	scrollBodyAnimated(offsetTop);

	function onTransitionEnd (event) {
		if(event.propertyName === 'transform') {
			body.removeEventListener('transitionend', onTransitionEnd, false);
			scrollBody(offsetTop);
			window.location.hash = '#'+ target.id;
		}
	}
}

function scrollBodyAnimated (offset) {
	const distance = (offset * -1) + currentScrollPosition; // * -1 creates a positive number.
	body.style.transition = TRANSITION;
	body.style.transform = 'translateY('+ (distance) +'px)';
}

function scrollBody (offset) {
	const distance = body.scrollTop + offset;
	body.style.transition = '';
	body.style.transform = '';

	window.scrollTo(0, distance);
}

export default { init };
