const menuBtn = document.querySelector('[data-menu-btn]');
const header = document.querySelector('[data-header]');
const menuItems = [].slice.call(document.querySelectorAll('[data-menu-item]'));

function init() {
	menuBtn.addEventListener('click', toggleMenu);
	menuItems.map(item => {
		item.addEventListener('click', toggleMenu);
	});
}

function toggleMenu() {
	header.classList.toggle('menu-is-open');
}

export default { init };

