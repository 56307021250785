const $cookieNotification = document.querySelector('[data-cookie-notification]');
const $acceptButton = document.querySelector('[data-cookie-accept-button]');
const $declineButton = document.querySelector('[data-cookie-decline-button]');
const googleAnalyticsId = $cookieNotification.getAttribute('data-google-analytics-id');

function init() {
    const hasAcceptedCookies = getAcceptedCookies();
    trackUser(hasAcceptedCookies);
    showNotification(hasAcceptedCookies);
}

function getAcceptedCookies() {
    const value = localStorage.getItem('acceptCookies');
    return value === null ? value : JSON.parse(value);
}

function onAccept(e) {
    $cookieNotification.classList.remove('show');
    localStorage.setItem('acceptCookies', true);
}

function onDecline(e) {
    $cookieNotification.classList.remove('show');
    localStorage.setItem('acceptCookies', false);
    trackUser(false);
}

function trackUser(hasAcceptedCookies) {
    const value = hasAcceptedCookies === null 
    ? false // has not made a choice yet, so track for now (option is opt-out)
    : !hasAcceptedCookies
    const property = 'ga-disable-' + googleAnalyticsId;
    window[property] = value;
}

function showNotification(hasAcceptedCookies) {
    if ( hasAcceptedCookies !== null ) { return; }
    $cookieNotification.classList.add('show');
    $acceptButton.addEventListener('click', onAccept);
    $declineButton.addEventListener('click', onDecline);
}

export default { init };
